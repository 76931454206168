import React, { useEffect, useState } from 'react';
import getConfig from 'next/config';
import Head from '@/componentWebs/headLayout';

const { publicRuntimeConfig } = getConfig();

const QuickMenu = ({}) => {
  const facebook_chat_widget = `
    <div className="fb-customerchat"
        attribution=setup_tool
        theme_color="#0084ff"
        page_id=${publicRuntimeConfig.FACEBOOK_PAGE_ID}
        logged_in_greeting="Bạn cần tư vấn gì?"
        logged_out_greeting="Bạn cần tư vấn gì?"
    >
    </div>`;

  return (
    <div
      className="facebook-button-chat"
      dangerouslySetInnerHTML={{ __html: facebook_chat_widget }}
    />
  );
};

export default QuickMenu;
